import { ref } from "vue";
import indexEN from "@/router/indexEN";
import index from "@/router";

const siteid = ref(1);

export function setSiteId(): void {
    console.log("before " + siteid.value);
    if(window.location.pathname.includes("/eng") || window.location.hostname.endsWith("en")){
    console.log("mid " + siteid.value);
        siteid.value = 2;
    } else {
        siteid.value = 1;
    }
    console.log("after " + siteid.value);
}

export function getSiteId(): number | null {
    return siteid.value;
}