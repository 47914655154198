import { getCache, setCache, syncDatainStorage } from "@/util/cache";
import type { Block, FooterNavigation, FooterServiceNavigation, appointmentOccasions } from "@/types";
import client from "@/api/craft";
import News from "@/stores/news";
import {loaded} from "@/stores/loading";
import Appointments from "@/stores/appointments";

let cache_expire_time = 60 * 60 * 2;

// New function to fetch the first element
async function fetchFirstElement(route: any) {
    const response = await client.GetFirstElement(route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1], route.name);
    if (response && response.data.entries && response.data.entries.length > 0) {
        return response.data.entries[0];
    }
    return null;
}

export async function GetFirstElement(route: any, blocks: any, seo: any, loading: any) {
    let firstElement = null;
    if (route.path.includes("/user")) return;
    const urlParams = new URLSearchParams(window.location.search);
    if (getCache(route.path.toString()) && urlParams.get('x-craft-live-preview') === null) {
        if (blocks != null) {
            blocks.value = JSON.parse(getCache(route.path.toString())) as Block[];
        }
        syncDatainStorage(route.path, blocks, route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1], route.name);
        loading.value = false;
        return;
    } else {
        // Fetch the first element separately
        firstElement = await fetchFirstElement(route);
        if (firstElement && firstElement.blocks &&firstElement.blocks.length > 0) {
            blocks.value[0] = firstElement.blocks[0];
        }
        loading.value = false;
    }

    // set seo values
    if(getCache(route.path.toString() + "_seo")) {
        seo.value = JSON.parse(getCache(route.path.toString() + "_seo"));
    } else if(firstElement !== null) {
        let general;
        if(getCache("general")) {
            general = JSON.parse(getCache("general"));
        } else {
            general = await client.GetGeneralGlobals();
            setCache("general", JSON.stringify(general), cache_expire_time);
        }
        seo.value = {
            metaDescription: firstElement.metaDescription ?? null,
            metaImage: firstElement.metaImage ?? null,
            metaTitle: firstElement.metaTitle ?? null,
            globalMetaImage: general.data.globalSet.metaImage[0] ?? null,
        };
        setCache(route.path.toString() + "_seo", JSON.stringify(seo.value), cache_expire_time);
    }
}

export async function AccessDataByHandle(route: any, blocks: any, seo: any, loading: any, fullEntry: any = null) {
    let entry = null;
    if (route.path.includes("/user")) return;
    const urlParams = new URLSearchParams(window.location.search);
    if (getCache(route.path.toString()) && urlParams.get('x-craft-live-preview') === null) {
        if (blocks != null) {
            blocks.value = JSON.parse(getCache(route.path.toString())) as Block[];
        }
        if (fullEntry) {
            fullEntry.value = JSON.parse(getCache(route.path.toString() + "_fullEntry"));
        }
        syncDatainStorage(route.path, blocks, route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1], route.name);
        loading.value = false;
        return;
    } else {
        // Fetch the remaining data
        const response = await client.GetPageByHandle(route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1], route.name);
        if (response && response.data.entries && response.data.entries.length > 0) {
            entry = response.data.entries.filter((entry: any) => Object.keys(entry).length > 0);
            if (entry.length > 0) {
                if (entry[0].blocks.length > 0) {
                    blocks.value = entry[0].blocks;
                    setCache(route.path.toString(), JSON.stringify(entry[0].blocks), cache_expire_time);
                }
                if (fullEntry) {
                    fullEntry.value = entry[0];
                    setCache(route.path.toString() + "_fullEntry", JSON.stringify(fullEntry.value), cache_expire_time);
                }
            }
        }
        loading.value = false;
    }

    // set seo values
    if(getCache(route.path.toString() + "_seo")) {
        seo.value = JSON.parse(getCache(route.path.toString() + "_seo"));
    } else if(entry !== null) {
        let general;
        if(getCache("general")) {
            general = JSON.parse(getCache("general"));
        } else {
            general = await client.GetGeneralGlobals();
            setCache("general", JSON.stringify(general), cache_expire_time);
        }
        seo.value = {
            metaDescription: entry[0].metaDescription ?? null,
            metaImage: entry[0].metaImage ?? null,
            metaTitle: entry[0].metaTitle ?? null,
            globalMetaImage: general.data.globalSet.metaImage[0] ?? null,
        };
        setCache(route.path.toString() + "_seo", JSON.stringify(seo.value), cache_expire_time);
    }
}

type Navigation = {
    navigation: any,
    staticLinks: any
}

export async function AccessNavData(navigation: any, staticLinks: any) {
    if (getCache("navigation")) {
        let dataNav = JSON.parse(getCache("navigation")) as Navigation;
        if(dataNav.navigation) {
            navigation.value = dataNav.navigation;
        }

        if(dataNav.staticLinks) {
            staticLinks.value = dataNav.staticLinks;
        }

        syncDatainStorage("navigation", dataNav, "navigation");
        return;
    } else {
        let response = await client.GetNavigation();

        navigation.value = response.data.globalSet.navigation;
        staticLinks.value = response.data.globalSet.statikLinks;

        const dataNav: Navigation = {
            navigation: response.data.globalSet.navigation,
            staticLinks: response.data.globalSet.statikLinks
        }

        setCache("navigation", JSON.stringify(dataNav), cache_expire_time);
        return;
    }
}

type Footer = {
    footer: any,
    footerService: any,
    logo: any
}

export async function AccessFooterData(footer: any, footerService: any, logo: any) {
    if (getCache("footer")) {
        const data = JSON.parse(getCache("footer")) as Footer;

        if(data.footer) {
            footer.value = data.footer;
        }

        if(data.footerService) {
            footerService.value = data.footerService;
        }

        if(data.logo) {
            logo.value = data.logo;
        }

        syncDatainStorage("footer", data, "footer");
        return;
    } else {
        let response = await client.GetFooter();
        
        footer.value = response.data.globalSet.footerNavigation;
        footerService.value = response.data.globalSet.footerServiceNavigation;
        logo.value = response.data.globalSet.footerLogo;

        const data: Footer = {
            footer: response.data.globalSet.footerNavigation,
            footerService: response.data.globalSet.footerServiceNavigation,
            logo: response.data.globalSet.footerLogo
        }

        setCache("footer", JSON.stringify(data), cache_expire_time);
        return;
    }
}

export async function AccessNewsListing(limit: number = 100) {

    return await client.GetNewsListing(limit).then((res) => {
        News.value = res.data.entries;
        return true;
    }).catch((err) => {
        return false;
    });
}

export async function AccessAppointmentsListing(limit: number = 100, appointmentCategory: string|null = null, appointmentOccasions: Array<appointmentOccasions> = []) {
    return await client.GetAppointmentListing(limit,appointmentCategory, appointmentOccasions).then((res) => {
        Appointments.value = res.data.entries;
        return true;
    }).catch((err) => {
        console.error(err.message);
        return false;
    });
}