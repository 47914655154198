<template>
    <div class="row-start-1 row-end-2 flex flex-col md:flex-row items-center lg:gap-20">
        <div class="order-2 md:order-1 my-6 lg:mb-0">
            <router-link to="/" aria-label="Home">
                <template v-if="logo">
                    <span class="block w-20 [&>svg]:!w-full" v-html="logo.inline">
                    </span>
                </template>
            </router-link>
        </div>
        <div
            class="grid grid-cols-12 w-full max-w-[550px] z-50 right-0 flex-grow order-1 lg:order-2 lg:min-w-[500px] lg:max-w-[1100px] bg-primary h-max py-8 lg:py-4 xl:py-8 px-3 md:pr-6 xl:px-20 rounded-lg md:translate-y-[-20%] lg:w-[65%] ml-auto">
            <div
                class="col-start-2 col-end-12 max-sm:mb-5 sm:col-start-1 sm:col-end-4 lg:col-start-1 lg:col-end-3 xl:col-end-2 grid justify-center lg:place-content-center">
                <Image v-if="consultant && typeof consultant == 'object' && consultant.avatar[0]"
                    class="w-full max-w-[100px] lg:max-w-[150px] lg:w-[80%] lg:mx-auto aspect-square object-cover rounded-full"
                    :src="consultant.avatar[0].url"
                    :srcset="consultant.avatar[0].srcset"
                    :alt="consultant.avatar[0].alt ? consultant.avatar[0].alt : ''" :title="consultant.avatar[0].title ? consultant.avatar[0].title : ''"/>
                <Avatar v-else class="w-full max-w-[100px] lg:mx-auto aspect-square rounded-full" />
            </div>
            <div
                :class="`col-start-2 m-2 max-sm:text-center col-end-12 sm:col-start-4 text-white sm:col-end-13 lg:translate-y-4 xl:translate-y-2 lg:col-start-3 xl:col-start-2 ${consultant && consultant.isDefault ? 'xl:col-end-5' : ''}`">
                <h3 v-if="consultant && consultant.headlingConsultant" class="font-medium h3">{{ consultant.headlingConsultant }}</h3>
                <h3 v-else class="font-medium h3">Haben Sie Fragen?</h3>
                <p class="text-xs leading-5 mt-3" v-if="consultant && consultant.firstname && consultant.lastname">
                  <a v-if="consultant && consultant.consultantPageLink && consultant.consultantPageLink[0]" class="group" :href="consultant.consultantPageLink[0].url ? consultant.consultantPageLink[0].url: '/'" target="_blank">
                    <span class="h4 group-hover:underline underline-offset-[3px]">
                      {{consultant.firstname}}</span> <span class="h4 group-hover:underline underline-offset-[3px]">{{ consultant.lastname }}<ArrowTopOnSquare class="w-4 ml-1 [&_svg]:w-4 [&_svg]:vertical [&_svg]:inline [&_path]:!stroke-1.5 inline vertical [&_svg]:group-hover:scale-110 [&_svg]:align-top" />
                    </span>
                  </a>
                  <span v-else class="h4">{{consultant.firstname}} <br v-if="consultant.firstname === 'Zentrale'">{{consultant.lastname }}</span><br>
                  {{ consultant.qualification ? consultant.qualification : '' }} <br>
                  {{consultant.title_consultant ? consultant.title_consultant : '' }}
                </p>
                <ul class="my-3 space-y-2 lg:hidden">
                  <li v-if="consultant && (consultant.mobilePhone || consultant.phoneNumber)" class="lg:w-[calc(50%-1.5rem)] xl:w-max">
                    <div class="flex flex-col flex-nowrap gap-3 flex-shrink-0">
                      <template v-if="consultant.phoneNumber">
                        <a :href="`tel:${consultant.phoneNumber}`" class="flex text-sm w-max items-center gap-3">
                        <span class="block w-3">
                            <PhoneReceiver class="w-4 [&_path]:!stroke-1.5" />
                        </span>
                          <span>{{ consultant.phoneNumber }}</span>
                        </a>
                      </template>
                      <template v-if="consultant.mobilePhone">
                        <a :href="`tel:${consultant.mobilePhone}`" class="flex text-sm w-max items-center gap-3">
                        <span class="block w-3">
                            <Phone class="w-5 [&_path]:!stroke-1 -ml-1" />
                        </span>
                          <span>{{ consultant.mobilePhone }}</span>
                        </a>
                      </template>
                    </div>
                  </li>
                    <li v-if="consultant && consultant.emailAddress">
                        <a :href="`mailto:${consultant.emailAddress}`" class="flex text-sm w-max items-center gap-3">
                            <span class="block w-3">
                                <Email class="w-4 [&_path]:!stroke-1" />
                            </span>
                            <span>{{ consultant.emailAddress }}</span>
                        </a>
                    </li>
                    <li>
                        <button type="button" @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Online'; consultantStore.houseStyle = houseStyle"
                                class="flex text-sm w-max items-center gap-3">
                            <span class="block w-3">
                                <Tag class="w-4 [&_path]:!stroke-1" />
                            </span>
                            <span>Online-Beratung</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Vor Ort'; consultantStore.houseStyle = houseStyle"
                                class="flex text-sm w-max items-center gap-3">
                            <span class="block w-3">
                                <Camera class="w-4 [&_path]:!stroke-1" />
                            </span>
                            <span>Beratungstermine vor Ort</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div v-if="consultant && consultant.isDefault && !route.path.startsWith('/user/')"
                class="col-start-2 col-end-13 space-y-2 lg:col-start-2 xl:col-start-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4 lg:items-center lg:mt-6 xl:mt-0">
                <div v-if="houseStyles" class="border-2 border-white rounded-full minimal-input">
                    <SmallSelect bgColor="primary" :chips="false" :hideDetails="false" :clearable="false"
                        :closableChips="false" label="Bitte wählen…"
                        placeholder="Bitte wählen…"
                        :items="houseStyles.map(entry => { return { title: entry.title, value: entry.id } })"
                        :multiple="false" @update="(e: number) => houseStyle = e.toString()" />
                </div>
                <!-- [&:has(input:focus-visible)]:border-4 -->
                <div class="flex border-2 border-white lg:bg-primary rounded-full minimal-input">
                    <v-text-field v-model="zipCode" type="number" bgColor="primary"
                                  base-color="#fff" :rules="[(value: string) => value.length == 5]"
                                  hide-spin-buttons
                                  class="w-[55%] no-focus-visible"
                                  single-line label="PLZ-Suche"></v-text-field>
                    <v-btn-lite @click="searchConsultant" :rules="[zipCode.length == 5]" :disabled="zipCode.length > 5"
                        class="!outline !outline-white !-outline-offset-1 hover:[&>*]:!text-white">Berater
                        finden</v-btn-lite>
                </div>
            </div>
            <ul
                class="my-8 hidden lg:flex flex-wrap xl:flex-nowrap items-center gap-6 justify-between col-start-2 xl:col-start-1 col-end-13 pl-[1%]">
                <li v-if="consultant && (consultant.mobilePhone || consultant.phoneNumber)" class="lg:w-[calc(50%-1.5rem)] xl:w-max">
                  <div class="flex flex-col flex-nowrap gap-3">
                    <template v-if="consultant.phoneNumber">
                      <a :href="`tel:${consultant.phoneNumber}`" class="flex text-sm items-center gap-5">
                        <span class="block w-3">
                            <PhoneReceiver class="w-5 [&_path]:!stroke-2" />
                        </span>
                        <span>{{ consultant.phoneNumber }}</span>
                      </a>
                    </template>
                    <template v-if="consultant.mobilePhone">
                      <a :href="`tel:${consultant.mobilePhone}`" class="flex text-sm items-center gap-5">
                        <span class="block w-3">
                            <Phone class="w-5 [&_path]:!stroke-1.5" />
                        </span>
                        <span>{{ consultant.mobilePhone }}</span>
                      </a>
                    </template>
                  </div>

                </li>
                <li v-if="consultant && consultant.emailAddress" class="lg:w-[calc(50%-1.5rem)] xl:w-max">
                    <a :href="`mailto:${consultant.emailAddress}`" class="flex text-sm items-center gap-5">
                        <span class="block w-3">
                            <Email class="w-6 [&_path]:!stroke-1" />
                        </span>
                        <span>{{ consultant.emailAddress }}</span>
                    </a>
                </li>
                <li v-if="consultant && consultant.id" class="lg:w-[calc(50%-1.5rem)] xl:w-max">
                    <button type="button"
                        @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Online'; consultantStore.houseStyle = houseStyle"
                        class="flex text-sm items-center gap-5">
                        <span class="block w-3">
                            <Camera class="w-6 [&_path]:!stroke-1" />
                        </span>
                        <span>Online-Beratung</span>
                    </button>
                </li>
                <li v-if="consultant && consultant.id" class="lg:w-[calc(50%-1.5rem)] xl:w-max">
                    <button type="button"
                        @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Vor Ort'; consultantStore.houseStyle = houseStyle"
                        class="flex text-sm items-center gap-5">
                        <span class="block w-3">
                            <Tag class="w-6 [&_path]:!stroke-1" />
                        </span>
                        <span>Beratungstermine vor Ort</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">

import Phone from "@/icons/Phone.vue";
import Email from "@/icons/Email.vue";
import Tag from "@/icons/Tag.vue";
import Camera from "@/icons/Camera.vue";
import {onMounted, ref, watch} from "vue";
import { ConsultantFooter, Consultant, ConsultantSearch } from "@/types";
import Avatar from "@/icons/Avatar.vue";

import consultantStore from "@/stores/consultant";

import client from "@/api/craft";
import { useRoute } from "vue-router";

const route = useRoute();
const consultant = ref<null | Consultant>(null);

const houseStyles = ref([]);
const houseStyle = ref('');

const zipCode = ref('');
import errorStore from "@/stores/error";
import PhoneReceiver from "@/icons/PhoneReceiver.vue";
import ArrowTopOnSquare from "@/icons/ArrowTopOnSquare.vue";
import router from "@/router";

const props = defineProps<{
    logo: any;
}>();

onMounted(() => {
  getConsultant();
})

watch(() => route.fullPath, () => {
  getConsultant();
})

function searchConsultant() {
  if(zipCode.value.length <= 5 && houseStyle.value.length > 0) {
    client.GetConsultant(zipCode.value, houseStyle.value, (data: ConsultantSearch) => {
      consultant.value = {
        ...data.consultant,
        isDefault: true
      };

      if (!data.success) {
        errorStore.value.message = data.errors.join(', ');
        errorStore.value.active = true;
      }
    })
  }else {
    errorStore.value.message = "Wählen sie einen Haustyp aus und geben Sie eine gültige PLZ ein.";
    errorStore.value.active = true;
  }
}
function getConsultant() {
  router.isReady().then(() => {
    if (route.params?.pathMatch && route.params?.pathMatch.length > 0) {
      // last slug for pages
      client.GetDefaultConsultant(route.params.pathMatch[route.params.pathMatch.length - 1]).then((res: {
        data: ConsultantFooter
      }) => {
        ValidateResponse(res);
      });
    } else if (route.name == 'House') {
      // get consultant for house
      client.GetDefaultConsultant(route.params.slug.toString()).then((res: { data: ConsultantFooter }) => {
        ValidateResponse(res);
      });
    } else if (route.name?.startsWith('User')) {
      // get consultant for house
      client.GetDefaultConsultant('default').then((res: { data: ConsultantFooter }) => {
        ValidateResponse(res);
      });
    } else {
      // TODO: if handle is default, get only default consultant
      client.GetDefaultConsultant(route.path == '/' ? 'startseite' : 'default').then((res: {
        data: ConsultantFooter
      }) => {
        ValidateResponse(res);
      });
    }
  }).catch((e) => {
    client.GetDefaultConsultant(route.path == '/' ? 'startseite' : 'default').then((res: {
      data: ConsultantFooter
    }) => {
      ValidateResponse(res);
    });
  });
}

function ValidateResponse(res: any) {
    if (res.data.entries && res.data.entries.length > 0 && res.data.entries[0].relationConsultantHouses !== undefined && res.data.entries[0].relationConsultantHouses.length > 0) {
        consultant.value = res.data.entries[0].relationConsultantHouses[0];
        consultant.value['isDefault'] = false;
    } else if (res.data.entries && res.data.entries.length > 0 && res.data.entries[0].relationConsultantAppointment !== undefined && res.data.entries[0].relationConsultantAppointment.length > 0) {
      consultant.value = res.data.entries[0].relationConsultantAppointment[0];
      consultant.value['isDefault'] = false;
    } else {
        consultant.value = res.data.globalSets[0].consultant[0];
        consultant.value['isDefault'] = true;
    }

    houseStyles.value = res.data.categories;
}



</script>
